import React, { memo } from 'react';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'; // Corregido la importación del icono de correo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'; // Asumiendo que este es el conjunto correcto para faWhatsapp
import styled, { keyframes } from 'styled-components';

const pulsate = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2); // Aumenta el tamaño del icono en un 10%
  }
  100% {
    transform: scale(1);
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Lumier', sans-serif;
  margin-bottom: 10px;
  width: 100%;
`;


const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #f0f0f0;
  border: 2px solid #e0e0e0;
  width: 100%;
  max-width: 1370px;
  margin-top: -40px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    width: 95%;
    padding: 20px;
    margin-top: -40px;
  }
`;


const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  @media (max-width: 768px) {
    font-size: 1.1em;
  }

  a {
    color: #1e1e1e;
    text-decoration: none;
    &:hover {
      color: #1b1b1b;
      text-decoration: underline;
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const WhatsappIcon = styled(FontAwesomeIcon)`
  color: #25D366;
  margin-top: 10px;
  font-size: 3em;
  animation: ${pulsate} 1.5s infinite ease-in-out;
`;




const FooterRioGrande = () => {

  return (
    <Container>
      <ContactContainer>

        <ContactInfo>
          <span><strong>Escuela Net Sport Academy G.E Airbus Padel Club </strong><br /> <br></br>
            <Icon icon={faPhone} />Eva pineda (+34) 625 305 902</span>
        </ContactInfo>

        <ContactInfo>
          <Icon icon={faEnvelope} />
          <a href="mailto:Geairbuspadel@gmail.com">geairbuspadel@gmail.com</a>


        </ContactInfo>


        <ContactInfo>
          <a href="https://wa.me/34625305902" target="_blank" rel="noopener noreferrer">
            <WhatsappIcon icon={faWhatsapp} />
          </a>
        </ContactInfo>






      </ContactContainer>




    </Container>
  );
};

export default memo(FooterRioGrande);
