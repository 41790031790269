import styled, { keyframes } from 'styled-components';
import Menu from '../../components/Menu/Navbar'
import Header from '../../components/Header/header'
import Logo from '../../components/Logo/logo'
import Footer from '../../components/Footer/footer'

import BannerPalomares from '../../components/Palomares/BannerPalomares/bannerpalomares'
import BodyPalomares from '../../components/Palomares/BodyPalomares/bodypalomares'
import Carrusel from '../../components/Palomares/Carrusel/carrusel'
import Contacto from '../../components/Palomares/Contacto/Contacto'
import Cards from '../../components/Palomares/SeleccionPalomares/cards'
import FooterPalomares from '../../components/Palomares/FooterPalomares/footerpalomares'
import PatrocinadoresPalomares from '../../components/Palomares/PatrocinadoresPalomares/patrocinadores'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const ContainerGeneral = styled.div`
  // Aplicar la animación de fade-in aquí
  animation: ${fadeIn} 2s ease-in-out;
  animation-fill-mode: forwards; // Asegurar que el elemento se mantenga en el estado final
  opacity: 0; // Inicializar en opacidad 0
`;

const Palomares = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <ContainerGeneral>

      <BannerPalomares />
      <BodyPalomares />
      <Carrusel />
      <Contacto />
      <Cards />
      <FooterPalomares />
      <PatrocinadoresPalomares />





</ContainerGeneral>
      <Footer />
      <></>
    </div>
  )
}

export default Palomares
