import React from 'react';
import Menu from '../../../components/Menu/Navbar';
import Header from '../../../components/Header/header';
import Logo from '../../../components/Logo/logo';
import styled from 'styled-components';
import Footer from '../../../components/Footer/footer';
import { ContainerGeneral, ElementoLista, Titulo, Lista, ContainerTexto } from '../../RioGrande/styletexto';
import FxView from '../../../components/FxView/fxview';
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 12px 18px;
  background-color: #a0f000;
  color: #000;
  text-decoration: none;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #007ac1;
    transform: scale(1.05);
  }
`;

const Actividades = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />
      <FxImagenBackGround icon={faCalendarAlt} />

      <ContainerGeneral>
        <ContainerTexto>
          <Titulo>Reserva de Pistas</Titulo>
          <p>
            Disfruta de nuestras instalaciones deportivas de primer nivel. Reserva tu pista de forma rápida y sencilla
            a través de nuestro sistema en línea.
          </p>
          <Titulo>Instalaciones Disponibles</Titulo>
          <Lista>
            <ElementoLista> Tenis (6 pistas de tierra batida)</ElementoLista>
            <ElementoLista> Pádel (2 pistas de cristal y 4 de muro)</ElementoLista>
            <ElementoLista> Squash</ElementoLista>
            <ElementoLista> Pickleball</ElementoLista>
          </Lista>

          <p>
            Para reservar una pista, accede a nuestro sistema de reservas haciendo clic en el siguiente enlace y elige
            la fecha y hora que más te convenga.
          </p>
          <Container>
            <StyledLink
              href="https://playtomic.io/tenant/4f2a8d4e-cd93-4460-916f-3dbf88a9e857"
              target="_blank"
              rel="noopener noreferrer"
            >
              📅 Reservar Pista Ahora
            </StyledLink>
          </Container>
        </ContainerTexto>
      </ContainerGeneral>

      <Footer />
    </div>
  );
};

export default Actividades;
