import styled from 'styled-components'

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1400px;
 

`;

export const ContainerTexto = styled.div`
  text-align: center;
`;

export  const Titulo = styled.h1`
  font-family: 'GarnetLight';
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 40px;
  margin-left: 15px;
  text-align: left;  
  font-size: 27px;
`;

export const Descripcion = styled.p`
  margin-right: 15px;
 margin-left: 15px;
  font-family: 'RNSMiles';
  font-size: 1.50rem; 
  line-height: 1.3;
  text-align: left; 
  font-weight: normal;
  color: #4f4f4f; 

  @media (max-width: 768px) {
    font-size: 1.25rem; 
  }
`;