import React from 'react'
import Menu from '../../../components/Menu/Navbar'
import Header from '../../../components/Header/header'
import Logo from '../../../components/Logo/logo'
import Footer from '../../../components/Footer/footer'
import {
  ContainerGeneral,
  ElementoLista,
  Titulo,
  Lista,
  ContainerTexto,
  ContainerImagenes,
  Imagen,
} from '../../RioGrande/styletexto'
import FxView from '../../../components/FxView/fxview'
import FxImagenBackGround from '../../../components/FxImagenBackGround/fximagenbackground'

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

const Actividades = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />
      <FxView />
      <FxImagenBackGround icon={faCalendarAlt} /> {/* Usar el nuevo icono aquí */}
      <ContainerGeneral>
        <ContainerTexto>
          <Titulo>RESERVA DE PISTAS</Titulo>

          <Lista>
            <ElementoLista>
              Para alquilar tu pista de pádel, simplemente escanea el código QR
              presente en este folleto. Este código te llevará directamente a
              nuestra plataforma de reservas en línea, donde podrás seleccionar
              la fecha, la hora y la pista de tu preferencia de manera rápida y
              sencilla. Disfruta de la comodidad de reservar desde tu teléfono
              móvil en cualquier momento y lugar. <br /><br />

              <strong>
                ¿Listo para jugar? ¡Escanea, o accede desde este enlace: <br />
                <a
                  href="https://playtomic.io/palomares-padel-club/2c0ed384-bde4-41b4-8759-5a8c4c0b1930?q=PADEL~2025-01-29~~~"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Reserva tu pista aquí
                </a>
                !
              </strong>

              <br /><br />

              <strong>
                También puedes reservar directamente tu pista de Pádel y Pickleball o evento al teléfono: (+34) 613 33 41 57
              </strong>
            </ElementoLista>
          </Lista>

          <ContainerImagenes>
            <Imagen
              src={require('./AlquilerPistasDePadel.jpg')}
              alt="Alquiler Pistas De Padel"
            />
          </ContainerImagenes>
        </ContainerTexto>
      </ContainerGeneral>
      <Footer />
    </div>
  )
}

export default Actividades
