import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeInOutRightToLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(10%);
  }

  
  50% {
    opacity: 1;
    transform: translateX(0); /* Se detiene en el centro */
  }

  
  100% {
    opacity: 0;
    transform: translateX(-10%);
  }
`;

export const AnimatedText = styled.span`
  opacity: 0;
  animation: ${fadeInOutRightToLeft} 3.5s cubic-bezier(0.75, 0, 0.25, 1) infinite;
  display: block;
  color: #a0f000;
  font-size: 38px;
  font-family: 'Goldbill';
  text-align: center;
  margin: 1em 10%;
  line-height: 1;


  @media (max-width: 768px) {
    font-size: 38px;
    margin-bottom: 70px;
   
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 630px;
  overflow: hidden;
  background-color: white;

  @media (max-width: 768px) {

   margin-top: -120px;
  }
`;

export const Titulo = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #a0f000;
  text-align: center;
  font-family: 'Kobern';
  width: 100%;
  line-height: 0.8;
  z-index: 3;

  @media (max-width: 768px) {
    /* top: 64%; */
    
  }
`;

export const Line1 = styled.span`
  animation: ${fadeIn} 2s ease-in-out;
  display: block;
  color: #000000;
  font-size: 40px;
  margin: 1em 10%;
  line-height: 1.3;

  @media (max-width: 879px) {
    margin-top:60px;
    font-size: 22px;
   
  }
`;

export const Line2 = styled.span`
  animation: ${fadeIn} 2s ease-in-out;
  display: block;
  color: #000000;
  font-size: 28px;
  font-family: 'RNSMiles';
  text-align: center;
  margin: 1em 10%;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 19px;
    margin: 1em 5%;
  }
`;