import React, { memo } from 'react';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  font-family: 'Lumier', sans-serif;
  margin-bottom: 30px;
`;

const SharedContainer = styled.div`
  max-width: 1370px;
  margin: 0 auto 30px auto;
  padding: 20px;
  background-color: #f0f0f0;
  border: 2px solid #e0e0e0;

  @media (max-width: 600px) {
    width: 95%;
    margin: 0 auto 20px auto;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  font-size: 1.3em;

  @media (max-width: 768px) {
    font-size: 1.1em;
  }

  a {
    color: #1e1e1e;
    text-decoration: none;
    &:hover {
      color: #1b1b1b;
      text-decoration: underline;
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const MapContainer = styled(SharedContainer)`
  height: 30vh;
  padding: 0;

  @media (max-width: 600px) {
    height: 50vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
`;


const FooterRioGrande = () => {
  return (
    <Container>
     <SharedContainer>

        <ContactInfo>
          <Icon icon={faExternalLinkAlt} />
          <span>
            <a href="https://golfbellavista.com/" target="_blank" rel="noopener noreferrer">
              www.golfbellavista.com
            </a>
          </span>
        </ContactInfo>




        </SharedContainer>

      <MapContainer>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12698.354198591489!2d-7.032254144580028!3d37.281182799999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd11cdddb52e3f1d%3A0x52f14d083f6ca7ba!2sClub%20de%20Golf%20Bellavista!5e0!3m2!1ses!2ses!4v1708390007660!5m2!1ses!2ses"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          width="100%"
          height="100%"
          title="Ubicación de la Escuela Net Sport Academy Bellavista en Google Maps" >
        </iframe>
      </MapContainer>

    </Container>
  );
};

export default memo(FooterRioGrande);
