import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import imagen1 from './1.jpg';
import imagen2 from './2.jpg';
import imagen3 from './3.jpg';
import imagen4 from './4.jpg';
import imagen5 from './5.jpg';

const Container = styled.div`
  margin: 20px auto;
  margin-bottom: 60px;
  max-width: 1370px;
  overflow: hidden; /* Evita el desbordamiento */
  box-sizing: border-box;

  @media (max-width: 767px) {
    width: 94vw; /* 94% en móviles */
  }
`;

const SliderContainer = styled.div`
  margin: 0 auto;
  max-width: 100%;
  height: 400px;
`;


const Image = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;
  margin: 0 auto;
`;

const Carrusel = () => {
  const images = [imagen1, imagen2, imagen3, imagen4, imagen5];

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: false,
  };

  return (
    <Container>
      <SliderContainer>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <Image src={image} alt={`Imagen ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </SliderContainer>
    </Container>
  );
};

export default Carrusel;
