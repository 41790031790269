import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom'; 
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { eventos } from '../../-EVENTOS/-Eventos'; // Importa los eventos



// Función para convertir una cadena de fecha en un objeto Date
const obtenerFechaInicio = (fecha) => {
  const fechaInicio = fecha.split(' - ')[0]; // Extrae la fecha de inicio en caso de un rango
  const [dia, mes, año] = fechaInicio.split('/').map(Number);
  return new Date(año, mes - 1, dia); // Los meses en JS van de 0 a 11
};
// Función para convertir una cadena de fecha en un objeto Date
const obtenerFechaFin = (fecha) => {
  const fechas = fecha.split(' - ');
  if (fechas.length === 2) {
    const [dia, mes, año] = fechas[1].split('/').map(Number);
    return new Date(año, mes - 1, dia);
  } else {
    return obtenerFechaInicio(fecha); // Si no hay fecha de fin, usa la fecha de inicio
  }
};
// Divide los eventos en próximos y pasados considerando la fecha de fin para eventos de varios días
const dividirEventos = (eventos) => {
  const hoy = new Date();
  hoy.setHours(0, 0, 0, 0); // Establece la hora al inicio del día para comparar correctamente

  const eventosProximos = eventos.filter(evento => {
    // const fechaInicio = obtenerFechaInicio(evento.fecha);
    const fechaFin = obtenerFechaFin(evento.fecha);
    return fechaFin >= hoy;
  });

  const eventosPasados = eventos.filter(evento => {
    const fechaFin = obtenerFechaFin(evento.fecha);
    return fechaFin < hoy;
  });

  return { eventosProximos, eventosPasados };
};


const EventoFicha = ({ evento }) => {
  const navigate = useNavigate();

  const handleFichaClick = () => {
    navigate(evento.ruta);
  };

  const hoy = new Date();
  hoy.setHours(0, 0, 0, 0); // Asegura comparar solo la fecha, sin la hora

  const fechaInicio = obtenerFechaInicio(evento.fecha);
  const fechaFin = obtenerFechaFin(evento.fecha);

  let estadoEvento = '';
  if (hoy < fechaInicio) {
    estadoEvento = 'Próximamente';
  } else if (hoy >= fechaInicio && hoy <= fechaFin) {
    estadoEvento = 'En curso';
  } else {
    estadoEvento = 'Finalizado';
  }

  const handleLeerMasClick = () => {
    navigate(evento.ruta);
  };

  const handleInscribeteClick = () => {
    window.location.href = evento.urlInscripcion;
  };

  return (
    <EventoFichaStyled onClick={handleFichaClick}>
      <ImagenContenedor>
        <ImagenEvento src={evento.imagenUrl} alt="Imagen del evento" />

        <EstadoEvento esFuturo={estadoEvento === 'Próximamente'} esEnCurso={estadoEvento === 'En curso'}>
  {estadoEvento}
</EstadoEvento>

      </ImagenContenedor>

      <NombreEvento>{evento.nombre}</NombreEvento>
      <FechaEvento>
        <FontAwesomeIcon icon={faCalendarAlt} size="1x" color="#4b4b4b" style={{ marginRight: '10px' }} />
        {evento.fecha}
      </FechaEvento>
      <DescripcionFicha>{evento.descripcion}</DescripcionFicha>
      <BotonesContenedor>
        <BotonLeerMas onClick={handleLeerMasClick}>Ampliar</BotonLeerMas>
        {evento.urlInscripcion && estadoEvento === 'Próximamente' && (
          <BotonInscribete onClick={handleInscribeteClick}>Inscríbete</BotonInscribete>
        )}
      </BotonesContenedor>
    </EventoFichaStyled>
  );
};
// Componente principal con el filtro
const App = () => {
  const [clubSeleccionado, setClubSeleccionado] = useState('Todos'); // Estado para el filtro

  const { eventosProximos, eventosPasados } = dividirEventos(eventos);

  // Filtra los eventos según el club seleccionado
  const filtrarPorClub = (eventos) => {
    return clubSeleccionado === 'Todos'
      ? eventos
      : eventos.filter(evento => evento.club === clubSeleccionado);
  };

  const eventosProximosFiltrados = filtrarPorClub(eventosProximos);
  const eventosPasadosFiltrados = filtrarPorClub(eventosPasados);

  return (
    <>
      <FiltroContenedor>
        <FiltroLabel>Filtrar por club:</FiltroLabel>
        <FiltroSelect value={clubSeleccionado} onChange={(e) => setClubSeleccionado(e.target.value)}>
          <option value="Todos">Todos</option>
          <option value="Palomares">Palomares</option>
          <option value="Rio Grande">Rio Grande</option>
          <option value="Bellavista">Bellavista</option>
          <option value="Airbus">Airbus</option>
          {/* Añade más clubs si es necesario */}
        </FiltroSelect>
      </FiltroContenedor>

      <ContenedorEventos>
        <Titulo>PRÓXIMOS EVENTOS</Titulo>
        {eventosProximosFiltrados.length > 0 ? (
          eventosProximosFiltrados.map(evento => (
            <EventoFicha key={evento.id} evento={evento} />
          ))
        ) : (
          <MensajeNoEventos>¡Atentos! Pronto anunciaremos nuevos eventos</MensajeNoEventos>
        )}
      </ContenedorEventos>

      <ContenedorEventos>
        <Titulo>EVENTOS PASADOS</Titulo>
        {eventosPasadosFiltrados.map(evento => (
          <EventoFicha key={evento.id} evento={evento} />
        ))}
      </ContenedorEventos>
    </>
  );
};

export default App;

const FiltroContenedor = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0px 0;
  padding-top: 30px;
  background: linear-gradient(90deg, #ece9e6 0%, #ffffff 100%);
  border-radius: 10px;
  font-family: 'Lumier', sans-serif;
  z-index: 20;

  @media (max-width: 768px) {
  
    gap: 10px;
  }
`;

const FiltroLabel = styled.label`
  font-size: 18px;
  margin-right: 15px;
  color: #4b4b4b;
  font-weight: bold;
`;

const FiltroSelect = styled.select`
  font-size: 16px;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
  color: #4b4b4b;
  font-family: 'Lumier', sans-serif;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    border-color: #a0f000;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(160, 240, 0, 0.5);
    border-color: #a0f000;
  }
`;


const EstadoEvento = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: ${({ esFuturo, esEnCurso }) => 
    esFuturo ? '#a0f000' : esEnCurso ? '#FFA500' : '#5a5a5a'};
  color: #fff;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;


const ContenedorEventos = styled.div`
position: relative; /* Modificado para permitir el posicionamiento absoluto del título */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: linear-gradient(90deg, #ece9e6 0%, #ffffff 100%);
 padding-top: 120px;
 padding-bottom: 40px;
 

 @media (max-width: 768px) {
  padding-top: 90px;
  }
`;

const EventoFichaStyled = styled.div`
  position: relative;
  font-family: 'Lumier', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: calc(30% - 40px);
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 9px 15px rgba(0,0,0,0.3);
  background-color: #ffffff;
  max-width: 550px;
  min-width: 350px;
  transition: all 0.3s ease;
  cursor: pointer; /* Hace que toda la ficha sea clickeable */

  &:hover {
    transform: scale(1.03);
    box-shadow: 0 18px 30px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
    max-height: 740px;
    min-width: 350px;
  }
`;


const ImagenContenedor = styled.div`
  width: 100%;
  overflow: hidden;

`;

const ImagenEvento = styled.img`
  width: 100%;
  
  min-height: 400px;
  max-height: 680px;
  object-fit: cover;
  object-position: top;
  transition: transform 0.5s ease;


  &:hover {
    transform: scale(1.07);
 
  }
`;

const NombreEvento = styled.h3`
  font-weight: bold;

  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 15px;
  text-align: left;
  width: 100%;
  color: #4b4b4b;
`;

const FechaEvento = styled.p`
  padding-left: 15px;
  text-align: left;
  width: 100%;
  margin-top: -10px;
  color: #4b4b4b;
`;

const DescripcionFicha = styled.p`
  font-family: 'RNSMiles';
  padding: 0 15px;
  text-align: left;
  width: 100%;
  margin-top: -5px;
  color: #4b4b4b;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 17px;
  
`;

const BotonLeerMas = styled.button`
  text-align: center;
  background-color: #a0f000;
  color: #000000;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 20px;
  margin-top: 15px;
  font-size: 12px;

  &:hover {
    background-color: #000000;
    color: white;
  }

  &:focus {
    outline: none;
  }
`;

const Titulo = styled.h1`
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'GarnetLight';
  font-weight: bold;
  font-size: 32px;
  z-index: 10;
  white-space: nowrap;
  padding: 10px;
/* border: 2px solid rgb(137, 137, 137); */
border-radius: 5px;
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
letter-spacing: 1.5px;
line-height: 1.2;

color: #474747;

  

  @media (max-width: 768px) {
    font-size: 24px; /* Reduce el tamaño de la fuente en dispositivos móviles */
    top: 30px;
  }
`;

const BotonInscribete = styled(BotonLeerMas)` // Utiliza los mismos estilos que BotonLeerMas
  background-color: #007bff; // Cambia el color si lo deseas
  &:hover {
    background-color: #0056b3; // Cambia el color al pasar el mouse si lo deseas
  }
`;

const BotonesContenedor = styled.div`
  display: flex;
  justify-content: center; // Centra los botones horizontalmente
  gap: 10px; // Añade un pequeño espacio entre los botones
  margin-top: 15px; // Asegúrate de ajustar el margen superior según necesites
`;

const MensajeNoEventos = styled.div`
  font-family: 'Lumier', sans-serif;
  color: #4b4b4b;
  text-align: center;
  padding: 20px;
  margin: 20px 0;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
`;

