import React, { memo } from 'react';

import styled from 'styled-components';



const Container = styled.div`
  text-align: center;
  font-family: 'Lumier', sans-serif;
  margin-bottom: 30px;
`;


const SharedContainer = styled.div`
  max-width: 1370px;
  margin: 0 auto 30px auto;
  padding: 20px;
  background-color: #f0f0f0;
  border: 2px solid #e0e0e0;

  @media (max-width: 600px) {
    width: 95%;
    margin: 0 auto 20px auto;
  }
`;

const MapContainer = styled(SharedContainer)`
  height: 30vh;
  padding: 0;

  @media (max-width: 600px) {
    height: 50vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
`;

const FooterRioGrande = () => {


  return (
    <Container>

      <MapContainer>
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d892.4955244731382!2d-6.07402659404936!3d37.31865840058751!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1ses!2ses!4v1708848204027!5m2!1ses!2ses"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          width="100%"
          height="100%"
          title="Ubicación de la Escuela Net Sport Academy Palomares en Google Maps" >
        </iframe>
      </MapContainer>


    </Container>
  );
};

export default memo(FooterRioGrande);
