import styled, { keyframes } from 'styled-components';
import Menu from '../../components/Menu/Navbar'
import Header from '../../components/Header/header'
import Logo from '../../components/Logo/logo'
import Footer from '../../components/Footer/footer'


import BannerAirbus from '../../components/Airbus/BannerAirbus/bannerairbus'
import BodyPalomares from '../../components/Airbus/BodyAirbus/bodyairbus'
import Carrusel from '../../components/Airbus/Carrusel/carrusel'
import Cards from '../../components/Airbus/SeleccionAirbus/cards'
import Contacto from '../../components/Airbus/Contacto/Contacto'
import FooterAirbus from '../../components/Airbus/FooterAirbus/footerairbus'
import PatrocinadoresAirbus from '../../components/Airbus/PatrocinadoresAirbus/patrocinadores'


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const ContainerGeneral = styled.div`
  // Aplicar la animación de fade-in aquí
  animation: ${fadeIn} 2s ease-in-out;
  animation-fill-mode: forwards; // Asegurar que el elemento se mantenga en el estado final
  opacity: 0; // Inicializar en opacidad 0
`;


const Airbus = () => {
  return (
    <div>
      <Menu />
      <Header />
      <Logo />




      <ContainerGeneral>

        <BannerAirbus />
      <BodyPalomares />
        <Carrusel />
        <Contacto />
       <Cards />
        <FooterAirbus />
       <PatrocinadoresAirbus /> 





      </ContainerGeneral>













      <Footer />
      <></>
    </div>
  )
}

export default Airbus
