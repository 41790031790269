import React, { memo } from 'react';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'; // Corregido la importación del icono de correo
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'; // Asumiendo que este es el conjunto correcto para faWhatsapp
import styled, { keyframes } from 'styled-components';

const pulsate = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2); // Aumenta el tamaño del icono en un 10%
  }
  100% {
    transform: scale(1);
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Lumier', sans-serif;
  margin-bottom: 10px;
  width: 100%;
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #f0f0f0;
  border: 2px solid #e0e0e0;
  width: 100%;
  max-width: 1370px;
  margin-top: -40px;


  @media (max-width: 768px) {
    width: 95%;
    padding: 20px;
    margin-top: -40px;
  }
`;


const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  @media (max-width: 768px) {
    font-size: 1.1em;
  }

  a {
    color: #1e1e1e;
    text-decoration: none;
    &:hover {
      color: #1b1b1b;
      text-decoration: underline;
    }
  }
`;




const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;


const WhatsappIcon = styled(FontAwesomeIcon)`
  color: #25D366;
  margin-top: 10px;
  font-size: 3em;
  animation: ${pulsate} 1.5s infinite ease-in-out;
`;




const TituloYBoton = styled.div`
  display: flex;
  flex-direction: column; /* Los elementos se alinean en columna por defecto */
  align-items: center;
  justify-content: center;
  padding: 10px;
 
`;

const BotonesContenedor = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5px auto;
 gap:0px 15px; 
 margin-bottom:20PX;

  @media (max-width: 768px) {
    flex-direction: row; /* Asegura que los botones estén en horizontal en pantallas pequeñas */
  }
`;



const BotonAlquiler = styled.button`
  font-family: 'GarnetLight';
 width: 610px;
  background-color: #80d0ff   ;
  color: #000000;
  border: transparent solid 0px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-decoration: none;
 text-align: center;

  &:hover {
    color: white;
    background-color: #000000; /* Un tono más oscuro para el efecto hover */
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 93vw;
    font-size: 12px;
    
  }
`;



const FooterRioGrande = () => {
  return (
    <p>
    <Container>
      <ContactContainer>

   
        <ContactInfo>
      

          <span> 
          <strong>Escuela Net Sport Academy Bellavista Huelva club </strong> <br/> <br></br>
            
            
             <Icon icon={faPhone} />Información General del Club BHC  <br></br> (+34) 645 67 69 85  </span>
        </ContactInfo>

        <ContactInfo>
          <span> <Icon icon={faPhone} /> Información Competición BHC <br></br>(+34) 638 35 57 69</span>
        </ContactInfo>

        <ContactInfo>
          <Icon icon={faEnvelope} />
          <a href="mailto:bhctenispadel@gmail.com">bhctenispadel@gmail.com</a>
        </ContactInfo>

    

        <ContactInfo>
          <a href="https://wa.me/34645676985" target="_blank" rel="noopener noreferrer">
            <WhatsappIcon icon={faWhatsapp} />
          </a>
        </ContactInfo>


      </ContactContainer>




    </Container>

<TituloYBoton>

<BotonesContenedor>
<BotonAlquiler onClick={() => window.location.href='/clubs/bellavista/alquilerdepistas'}>Alquiler De Pistas</BotonAlquiler>
</BotonesContenedor>

</TituloYBoton>
</p>

  );
};

export default memo(FooterRioGrande);
