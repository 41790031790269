import React, { memo } from 'react';

import styled from 'styled-components';



const Container = styled.div`
  text-align: center;
  font-family: 'Lumier', sans-serif;
  margin-bottom: 30px;
`;


const SharedContainer = styled.div`
  max-width: 1370px;
  margin: 0 auto 30px auto;
  padding: 20px;
  background-color: #f0f0f0;
  border: 2px solid #e0e0e0;

  @media (max-width: 600px) {
    width: 95%;
    margin: 0 auto 20px auto;
  }
`;

const MapContainer = styled(SharedContainer)`
  height: 30vh;
  padding: 0;

  @media (max-width: 600px) {
    height: 50vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
`;

const FooterRioGrande = () => {


  return (
    <Container>

   
      <MapContainer>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.4605905520307!2d-6.0711555590317055!3d37.33160303767973!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1212b5266b8adb%3A0xeb1eeb23e3b31fe4!2sClub%20G.E%20Airbus%20Sevilla!5e0!3m2!1ses!2ses!4v1708389739483!5m2!1ses!2ses"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          width="100%"
          height="100%"
          title="Ubicación de la Escuela Net Sport Academy Airbus en Google Maps" >
        </iframe>
      </MapContainer>

    </Container>
  );
};

export default memo(FooterRioGrande);
